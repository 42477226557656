import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './pagination.css';

class Pagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: props.page,
      limit: props.limit
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.page !== nextProps.page) {
      this.setState({ page: nextProps.page });
    }
  }

  onPageClick = (page) => {
    if (this.props.onChange) {
      this.props.onChange(page);
    }
  }

  renderLink(page, content) {
    let renderUrl = this.props.children;

    if (renderUrl) {
      return <Link to={renderUrl(page)}><button>{content}</button></Link>;
    } else {
      return <button href='#' onClick={() => this.onPageClick(page)}>{content}</button>;
    }
  }

  render() {
    let { page, limit } = this.state;
    let { count } = this.props;
    let size = this.props.size || 10;
    let totalPage = Math.ceil(count / limit);

    if (totalPage <= 1) {
      return <div className='pagination'></div>;
    }

    let startPage = Math.floor((page - 1)/ size) * size + 1;
    let endPage = Math.min(startPage + size - 1, totalPage);
    let pageButtons = [];

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(<span key={i} className={i === page ? 'active' : ''}>
        {this.renderLink(i, i)}
      </span>);
    }

    return <div className='pagination'>
      {startPage !== 1 && this.renderLink(startPage - 1, '이전')}
      {startPage - size > size && <span>{this.renderLink(1, 1)} &hellip; </span>}
      {pageButtons}
      {endPage < (totalPage - size) && <span> &hellip; {this.renderLink(totalPage, totalPage)}</span>}
      {endPage < totalPage && this.renderLink(endPage + 1, '다음')}
    </div>;
  }
}

export default Pagination;
