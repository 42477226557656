import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SubHeader from '../components/sub-header';
import Loader from '../components/loader';
import server from '../libs/server';
import boardInfo from '../bbs-board-info.json';
import { truncateString, getPastDate } from '../libs/util';
import './bbs-widget.css';

class BbsWidget extends Component {
  loadPosts = async () => {
    let res = await server.get(`/bulletin-boards/${boardInfo[this.props.boardName].id}/bulletin-posts?limit=5`);
    return res.data;
  }

  renderItem(entry) {
    let url = `/community/${this.props.boardName}/${entry.id}`;

    return <li key={entry.id}>
      {!!entry.thumbUrl &&
        <div className='bbs-widget-item-thumb'>
          <Link to={url}>
            <img src={entry.thumbUrl} width={60} height={60} alt={entry.id} />
          </Link>
        </div>
      }

      <div className='bbs-widget-item-title'>
        <Link to={url}>
          {truncateString(entry.title, 50)}
        </Link>
      </div>
      <div className='bbs-widget-item-date'>
        {getPastDate(entry.createdAt)}
      </div>
      <div className='bbs-widget-item-excerpt'>
        <Link to={url}>
          {truncateString(entry.excerpt, 120)}
        </Link>
      </div>
    </li>;
  }

  render() {
    let info = boardInfo[this.props.boardName];

    return <div className='bbs-widget'>
      <SubHeader>{info.name} <Link to={`/community/${this.props.boardName}`}><button className='bbs-widget-more-button'>더보기</button></Link></SubHeader>
      <Loader onLoad={this.loadPosts}>
        {entries => <ul className='bbs-widget-list'>
          {entries.map(entry => this.renderItem(entry))}
        </ul>}
      </Loader>
    </div>;
  }
}

export default BbsWidget;
