import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import SubHeader from '../components/sub-header';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import Pagination from '../components/pagination';
import Loader from '../components/loader';
import ContentStyle from '../components/content-style';
import server from '../libs/server';
import './course-view.css';
import CloseIcon from '../images/close-icon.png';

class CourseView extends Component {
  state = {
    applicable: false
  };

  get courseId() {
    return this.props.match.params.courseId;
  }

  loadData = async () => {
    let res = await server.get(`/sonsure-courses/${this.courseId}`);

    this.setState({
      applicable: this.checkDate(res.data)
    });

    return res.data;
  }

  loadApplications = async (page = 1) => {
    const limit = 20;
    let res = await server.get(`/sonsure-course-applications?courseId=${this.courseId}&limit=${limit}&offset=${(page - 1) * limit}&sort=-id`);
    return res;
  }

  checkDate(entry) {
    let today = new Date();
    let startDate = new Date(entry.startDate);
    let endDate = new Date(entry.endDate);

    return today > startDate && today < endDate;
  }

  isActive(entry) {
    if (!this.state.applicable || (entry.applicationLimit && (entry.applicationCount >= entry.applicationLimit))) {
      return false;
    }

    return true;
  }

  renderButtons = entry => {
    let active = this.isActive(entry);

    return <div className='course-view-buttons'>
      {active &&
        <Link to={`/courses/${this.courseId}/apply`}><button className='large primary'>접수하기</button></Link>
      }

      {!active &&
        <button className='large' disabled>접수마감</button>
      }

      <Link to={`/courses`}><button className='large'>목록으로</button></Link>
    </div>;
  }

  renderEntry = entry => {
    return <div className='side-gutter'>
      <SubHeader>{entry.title}</SubHeader>

      <dl className='data-table'>
        <dt>접수기간</dt>
        <dd>{entry.startDate} ~ {entry.endDate}</dd>
        <dt>교육기간</dt>
        <dd>{entry.schedule}</dd>
        <dt>모집인원</dt>
        <dd>{entry.applicationLimit ? entry.applicationLimit : '제한없음'}</dd>
      </dl>

      {this.renderButtons(entry)}

      <div dangerouslySetInnerHTML={{ __html: entry.content }} />

      {this.renderButtons(entry)}
    </div>;
  }

  renderApplication = application => {
    return <div key={application.id} className='course-application-item'>
      <div className='course-application-name'>
        {application.name}
      </div>
      <div className='course-application-message'>
        {application.message}
      </div>
      <div className='course-application-date'>
        {moment(application.createdAt).format('YYYY-MM-DD')}
      </div>
      {this.state.applicable &&
        <Link to={`/course-applications/${application.id}/cancel`}><button title='신청취소'><img src={CloseIcon} alt='cancel' /></button></Link>
      }
    </div>;
  }

  render() {
    return <Page>
      <PageHeader>교육</PageHeader>
      <Breadcrumb>
        <BreadcrumbItem linkTo='/courses'>교육</BreadcrumbItem>
      </Breadcrumb>

      <Loader onLoad={this.loadData}>
        {this.renderEntry}
      </Loader>

      <Loader onLoad={this.loadApplications}>
        {(res, load) => <div className='course-application-box'>
          <div className='course-application-list side-gutter'>
            {res.data.filter(p => !p.canceled).map(this.renderApplication)}
          </div>

          <Pagination {...res.pagination} onChange={load} />
        </div>}
      </Loader>

      <ContentStyle />
    </Page>;
  }
}

export default CourseView;
