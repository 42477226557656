import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import server from '../libs/server';

class Logout extends Component {
  constructor(props) {
    super(props);
    server.logOut();
  }

  render() {
    return <Redirect to='/' />;
  }
}

export default Logout;
