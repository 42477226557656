import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import SubHeader from '../components/sub-header';
import { Form, FormField } from '../components/form';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import server from '../libs/server';

class ResetPasswordComplete extends Component {
  state = {
    completed: false
  };

  get code() {
    return this.props.match.params.code;
  }

  onSubmit = async values => {
    if (values.password !== values.passwordConfirm) {
      throw new Error('비밀번호 확인이 맞지 않습니다.');
    }

    await server.post(`/users/password-reset?code=${this.code}`, {
      password: values.password
    });

    this.setState({ 
      completed: true
    });
  }

  render() {
    if (server.user) {
      return <Redirect to='/' />;
    }

    return <Page>
      <PageHeader>비밀번호 초기화</PageHeader>
      <Breadcrumb>
        <BreadcrumbItem linkTo={`/reset-password`}>비밀번호 초기화</BreadcrumbItem>
      </Breadcrumb>

      <div className='row gutter'>
        {!this.state.completed &&
          <div className='col full'>
            <div className='page-help'>변경하실 비밀번호를 입력하세요.</div>

            <SubHeader>비밀번호 입력</SubHeader>

            <Form horizontal onSubmit={this.onSubmit}>
              <input name='captcha' />

              <FormField label='비밀번호'>
                <input type='password' name='password' minLength={4} required autoFocus />
              </FormField>

              <FormField label='비밀번호 확인'>
                <input type='password' name='passwordConfirm' />
              </FormField>
            </Form>
          </div>
        }
      
        {this.state.completed &&
          <div className='col full'>
            <div className='page-help'>
              <p>입력하신 비밀번호로 비밀번호가 초기화되었습니다.</p>
              <p>
                <Link to='/login'><button className='primary large'>로그인</button></Link>
                <Link to='/'><button className='large'>홈으로</button></Link>
              </p>
            </div>
          </div>
        }
      </div>
    </Page>;
  }
}

export default ResetPasswordComplete;
