import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BrandLogo from '../images/top-logo.png';
import './top-logo.css';

export default class TopLogo extends Component {
  render() {
    let classNames = ['top-logo'];

    return <div className={classNames.join(' ')}>
      <div className='container'>
        <Link to='/'>
          <img src={BrandLogo} alt='sonsure.co.kr' width={120} height={96} />
        </Link>
      </div>
    </div>;
  }
}
