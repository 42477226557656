import React, { Component } from 'react';
import PageHeader from '../components/page-header';
import './about-header.css';

import AboutHeaderImage1 from '../images/about-header-image1.png';
import AboutHeaderImage2 from '../images/about-header-image2.png';
import AboutHeaderImage3 from '../images/about-header-image3.png';
import AboutHeaderImage4 from '../images/about-header-image4.png';

class AboutHeader extends Component {
  render() {
    return <PageHeader>
      <div className='about-header'>
        <img id='about-header-image-1' src={AboutHeaderImage1} alt='천규석' width={207} height={232} />
        <img id='about-header-image-2' className='sm-hidden' src={AboutHeaderImage2} alt='도시없는 농촌은 있어도, 농촌없는 도시는 없다.' width={355} height={64} />
        <img id='about-header-image-3' src={AboutHeaderImage3} alt='SONSURE' width={140} height={112} />
        <img id='about-header-image-4' src={AboutHeaderImage4} alt='070-8899-3311' width={200} height={60} />
      </div>
    </PageHeader>
  }
}

export default AboutHeader;
