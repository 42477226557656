import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import { Stepper, Step } from '../components/stepper';
import Loader from '../components/loader';
import server from '../libs/server';
import './register.css';

class RegisterActivation extends Component {
  get code() {
    return this.props.match.params.code;
  }

  activateCode = async values => {
    await server.post(`/users/register/activate?code=${this.code}`);
  }

  render() {
    return <Page>
      <PageHeader>회원가입</PageHeader>
      <Breadcrumb ref={elem => this.topElem = elem}>
        <BreadcrumbItem linkTo={`/register`}>회원가입</BreadcrumbItem>
      </Breadcrumb>

      <Stepper>
        <Step>약관동의</Step>
        <Step>정보입력</Step>
        <Step>이메일 인증</Step>
        <Step active>가입완료</Step>
      </Stepper>

      <Loader onLoad={this.activateCode}>
        <div className='register-content'>
          <div className='row gutter'>
            <div className='register-help col full'>
              <h3>회원가입이 완료되었습니다.</h3>
              <p>회원가입을 해주셔서 감사합니다! 로그인 페이지를 통해 로그인해주시기 바랍니다.</p>
            </div>

            <div className='register-buttons col full'>
              <Link to='/login'><button className='primary large'>로그인</button></Link>
              <Link to='/'><button className='large'>홈으로</button></Link>
            </div>
          </div>
        </div>
      </Loader>
    </Page>;
  }
}

export default RegisterActivation;
