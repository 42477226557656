import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'query-string';
import server from '../libs/server';

export default class LoginCallback extends Component {
  constructor(props) {
    super(props);

    const parsed = qs.parse(this.props.location.search);

    this.state = {
      success: parsed.success === 'true',
      authToken: parsed.authToken,
      redirectTo: null
    };
  }

  async componentDidMount() {
    if (this.state.success) {
      await server.authenticate(this.state.authToken);
      this.setState({ redirectTo: '/' });
    } else {
      alert('로그인에 실패하였습니다.');
      this.setState({ redirectTo: '/login' });
    }
  }

  render() {
    return <div>
      {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
    </div>;
  }
}
