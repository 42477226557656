import React, { Component } from 'react';
import server from '../libs/server';

export default class ContentStyle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      css: ''
    };
  }

  async componentDidMount() {
    if (this.props.href) {
      this.setState({ css: this.props.href });
    } else if (this.props.pageGroupName) {
      try {
        let res = await server.get(`/page-groups/${this.props.pageGroupName}`);
        this.setState({ css: `${server.apiBase}/content-styles/${res.data.contentStyleId}/style.css` });
      } catch (e) {
        ;
      }
    } else if (this.props.contentStyleId) {
      this.setState({ css: `${server.apiBase}/content-styles/${this.props.contentStyleId}/style.css` });
    } else {
      this.setState({ css: `${server.apiBase}/content-styles/primary/style.css` });
    }
  }
  
  render() {
    return <div>
      <link rel='stylesheet' type='text/css' href={this.state.css} />
    </div>;
  }
}
