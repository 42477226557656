import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../images/loading.png';
import './loader.css';
import ErrorIcon from '../images/error-icon.png';

export default class Loader extends React.Component {
  static propTypes = {
    onLoad: PropTypes.func,
    defaultArguments: PropTypes.array,
    active: PropTypes.bool
  };

  static defaultProps = {
    active: null,
    defaultArguments: []
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: props.active !== false,
      error: null,
      result: null,
      arguments: props.defaultArguments
    };

    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.load(...this.state.arguments);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active) {
      this.setState({ loading: nextProps.active !== false });
    }
  }

  load = (...args) => {
    if (this.props.onLoad) {
      this.setState({ loading: true }, async () => {
        try {
          let result = await this.props.onLoad(...args);

          if (this.mounted) {
            this.setState({
              loading: false,
              error: null,
              result,
              arguments: args
            });
          }
        } catch (e) {
          if (this.mounted) {
            this.setState({ error: e.message, result: null });
          }
        }
      });
    }
  }

  render() {
    let classNames = ['loader'];

    if (this.props.active === true) {
      classNames.push('cover');
    }

    if (this.state.loading) {
      return <div className={classNames.join(' ')}>
        {!this.state.error &&
          <img src={Loading} width={63} height={63} alt='loading' />
        }

        {!!this.state.error &&
          <div className='loader-error'>
            <div><img src={ErrorIcon} alt='error' width={64} height={64} /></div>

            <div>{this.state.error}</div>

            {this.props.onLoad &&
              <div><button onClick={() => this.load(...this.state.arguments)}>다시시도</button></div>
            }
          </div>
        }
      </div>;
    } else if (typeof this.props.children === 'function') {
      return this.props.children(this.state.result, this.load);
    } else {
      return <div>{this.props.children}</div>;
    }
  }
}
