import React, { Component } from 'react';
import Page from '../components/page';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import { TabMenu, TabItem } from '../components/tab-menu';
import Loader from '../components/loader';
import ContentStyle from '../components/content-style';
import AboutHeader from './about-header';
import server from '../libs/server';

class About extends Component {
  state = {
    page: null,
    loading: true
  };

  componentDidMount() {
    this.loadData(this.props.pageName);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pageName !== nextProps.pageName) {
      this.loadData(nextProps.pageName);
    }
  }
  
  async loadData(pageName) {
    this.setState({ loading: true });
    let res = await server.get(`/pages/${pageName}`);
    this.setState({
      page: res.data,
      loading: false
    });
  }

  render() {
    return <Page>
      <AboutHeader />
      <Breadcrumb>
        <BreadcrumbItem linkTo='/about'>소개</BreadcrumbItem>
      </Breadcrumb>
      <TabMenu>
        <TabItem linkTo='/about' active={this.props.pageName === 'about-sonsure'}>손수레 소개</TabItem>
        <TabItem linkTo='/about-values' active={this.props.pageName === 'about-values'}>손수레 가치</TabItem>
        <TabItem linkTo='/about-activities' active={this.props.pageName === 'about-activities'}>하는 일</TabItem>
        <TabItem linkTo='/about-relationships' active={this.props.pageName === 'about-relationships'}>인연 맺기</TabItem>
      </TabMenu>

      <ContentStyle />

      {this.state.page &&
        <div dangerouslySetInnerHTML={{ __html: this.state.page.content }} />
      }

      <Loader active={this.state.loading} />
    </Page>;
  }
}

export default About;
