import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getPastDate, truncateString } from '../libs/util';
import './bbs-list-table.css';
import CommentIcon from '../images/comment-icon.png';
import LockIcon from '../images/lock-icon.png';
import ReplyIcon from '../images/reply-icon.png';

class BbsListTable extends Component {
  render() {
    let boardName = this.props.boardName;
    let entries = this.props.entries;

    return <div className='side-gutter'><table className='bbs-list-table'>
      <thead>
        <tr>
          <th>제목</th>
          <th>작성자</th>
          <th className='sm-hidden'>작성일</th>
          <th className='sm-hidden'>조회</th>
        </tr>
      </thead>

      <tbody>
        {entries.map(entry => <tr key={entry.id} className='bbs-list-table-item'>
          <td>
            {!!entry.category && entry.depth === 0 &&
              <span className='bbs-list-table-category-badge'>{entry.category.title}</span>
            }

            <Link to={`/community/${boardName}/${entry.id}`}>
              <span className='bbs-list-table-title' style={{ paddingLeft: entry.depth * 10 + 'px' }}>
                {entry.depth > 0 && <img src={ReplyIcon} alt='답글' width={16} height={16} />}
                {truncateString(entry.title, 100)}
              </span>
            </Link>

            {entry.commentCount > 0 &&
              <span className='bbs-list-table-comment-count'>
                <img src={CommentIcon} alt='댓글' width={18} height={14} />
                <span>{entry.commentCount}</span>
              </span>
            }
            
            {entry.secret &&
              <span className='bbs-list-table-secret'>
                <img src={LockIcon} alt='비밀글' width={16} height={16} />
              </span>
            }
          </td>
          <td className='fit-content'>{entry.author.nickname}</td>
          <td className='fit-content sm-hidden'>{getPastDate(entry.createdAt)}</td>
          <td className='sm-hidden' style={{ width: '60px' }}>{entry.readCount}</td>
        </tr>)}
      </tbody>
    </table></div>;
  }
}

export default BbsListTable;
