import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import { Stepper, Step } from '../components/stepper';
import server from '../libs/server';
import RegisterAgreement from './register-agreement';
import RegisterForm from './register-form';
import RegisterComplete from './register-complete';
import './register.css';

class Register extends Component {
  topElem = null;

  state = {
    step: 'agreement',
    user: null
  };

  changeStep(step, user = this.state.user) {
    this.setState({ step, user });

    if (this.topElem) {
      let node = ReactDOM.findDOMNode(this.topElem);
      node.scrollIntoView();
    }
  }

  render() {
    if (server.user) {
      return <Redirect to='/' />;
    }

    return <Page>
      <PageHeader>회원가입</PageHeader>
      <Breadcrumb ref={elem => this.topElem = elem}>
        <BreadcrumbItem linkTo={`/register`}>회원가입</BreadcrumbItem>
      </Breadcrumb>

      <Stepper>
        <Step active={this.state.step === 'agreement'}>약관동의</Step>
        <Step active={this.state.step === 'form'}>정보입력</Step>
        <Step active={this.state.step === 'complete'}>가입완료</Step>
      </Stepper>

      <div className='register-content'>
        {this.state.step === 'agreement' && <RegisterAgreement onNext={() => this.changeStep('form')} />}
        {this.state.step === 'form' && <RegisterForm onNext={(user) => this.changeStep('complete', user )} />}
        {this.state.step === 'complete' && <RegisterComplete user={this.state.user} />}
      </div>
    </Page>;
  }
}

export default Register;
