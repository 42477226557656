import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import Pagination from '../components/pagination';
import { Form } from '../components/form';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import Loader from '../components/loader';
import ContentStyle from '../components/content-style';
import server from '../libs/server';
import './bbs-view.css';
import TimeIcon from '../images/time-icon.png';
import ListIcon from '../images/list-icon.png';
import CloseIcon from '../images/close-icon.png';
import DownloadIcon from '../images/download-icon.png';
import { getPastDate } from '../libs/util';
import boardInfo from '../bbs-board-info.json';

class BbsCommentForm extends Component {
  input = null;

  onCommentWrite = async (values) => {
    let res = await server.post(`/bulletin-posts/${this.props.postId}/bulletin-comments`, values);

    if (this.props.onComment) {
      this.props.onComment(res.data);
    }

    this.input.value = '';
  }

  render() {
    if (!server.user) {
      return <div></div>;
    }

    return <Form
      onSubmit={this.onCommentWrite}
      submitButton='댓글달기'
    >
      <input name='captcha' />
      <textarea name='content' placeholder='내용을 입력하세요.' rows={3} required ref={input => this.input = input} />
    </Form>;
  }
}

class BbsCommentList extends Component {
  state = {
    pagination: { page: 1, offset: 0, limit: 10 },
    comments: [],
    loading: true
  };

  componentDidMount() {
    this.loadComments();
  }

  loadComments = async (page = this.state.pagination.page) => {
    this.setState({ loading: true });

    let limit = this.state.pagination.limit;
    let offset = (page - 1) * limit;
    let res = await server.get(`/bulletin-posts/${this.props.postId}/bulletin-comments?offset=${offset}&limit=${limit}&sort=-id`);

    this.setState({
      pagination: res.pagination,
      comments: res.data,
      loading: false
    });
  }

  onCommentAdd = comment => {
    this.loadComments(1);
  }

  onCommentDelete = async commentId => {
    if (window.confirm('정말 댓글을 삭제하시겠습니까?')) {
      await server.del(`/bulletin-comments/${commentId}`);
      this.loadComments();
    }
  }

  renderComment(comment) {
    return <div key={comment.id} className='bbs-comment-item'>
      <div className='bbs-comment-avatar' style={{ backgroundImage: `url(${server.apiBase}/users/${comment.author.id}/avatar)` }} /> 

      <div className='bbs-comment-body'>
        <div className='bbs-comment-description'>
          <span>{comment.author.nickname}</span>
          <span>{getPastDate(comment.createdAt)}</span>
        </div>

        <div className='bbs-comment-content'>
          {comment.content}
        </div>

        <button className='bbs-comment-delete' onClick={() => this.onCommentDelete(comment.id)}>
          <img src={CloseIcon} alt='삭제' width={14} height={14} />
        </button>
      </div>
    </div>;
  }

  render() {
    return <div className='bbs-comment-list side-gutter'>
      <BbsCommentForm postId={this.props.postId} onComment={this.onCommentAdd} />

      {this.state.comments.map(comment => this.renderComment(comment))}

      <Pagination {...this.state.pagination} onChange={page => this.loadComments(page)} />
    </div>;
  }
}

class BbsView extends Component {
  get postId() {
    return this.props.match.params.postId;
  }

  get boardName() {
    return this.props.match.params.boardName;
  }

  isAllowed(featureName) {
    return boardInfo[this.boardName].allows.indexOf(featureName) >= 0;
  }

  loadPost = async () => {
    let res = await server.get(`/bulletin-posts/${this.postId}`);
    return res.data;
  }

  loadFiles = async () => {
    let res = await server.get(`/bulletin-posts/${this.postId}/files?limit=100`);
    return res.data;
  }

  renderFileList(entry) {
    return <div className='bbs-post-view-file-list'>
      <Loader onLoad={this.loadFiles}>
        {files => {
          let downloadables = files.filter(file => entry.content.indexOf(file.url) < 0);

          return <ul>
            {downloadables.map(file => (<li key={file.id}>
              <a href={file.url} target='_blank' type={file.type} download rel='noopener noreferrer'>
                <span>{file.name}</span>
                <img src={DownloadIcon} alt='download' width={14} height={14} />
              </a>
            </li>))}
          </ul>;
        }}
      </Loader>
    </div>;
  }

  renderPost(entry) {
    return <div className='bbs-post-view side-gutter'>
      <div className='bbs-post-view-header'>
        <ul className='bbs-post-view-description sm-hidden'>
          <li><span>{entry.author.nickname}</span></li>
          <li>
            <img src={TimeIcon} alt='작성일' width={14} height={14} />
            <span>{moment(entry.createdAt).format('YYYY년 MM월 DD일 A hh시 mm분')}</span>
          </li>
          <li><span>조회 {entry.readCount}</span></li>
        </ul>

        <div className='bbs-post-view-title'>
          <span>{entry.title}</span>

          {!!entry.category &&
            <span className='bbs-category-badge'>{entry.category.title}</span>
          }
        </div>
      </div>

      <div className='bbs-post-view-content' dangerouslySetInnerHTML={{ __html: entry.content }} />

      {this.renderFileList(entry)}

      <div className='bbs-post-view-footer'>
      </div>
    </div>;
  }

  render() {
    return <Page>
      <Loader onLoad={this.loadPost}>
        {data => <div>
          <ContentStyle contentStyleId={data.board.contentStyleId} />

          <PageHeader>{data.board.title}</PageHeader>
          <Breadcrumb>
            <BreadcrumbItem linkTo={`/community/${this.boardName}`}>{data.board.title}</BreadcrumbItem>
          </Breadcrumb>

          {this.renderPost(data)}

          {this.isAllowed('comment') &&
            <BbsCommentList postId={this.postId} />
          }

          <div className='bbs-post-view-buttons side-gutter'>
            <Link to={`/community/${this.boardName}`}><button className='icon-button'><img src={ListIcon} alt='목록' width={14} height={14} /> <span>목록</span></button></Link>

            {server.user && server.user.id === data.author.id &&
              <Link to={`/community/${this.boardName}/${this.postId}/modify`}><button>수정</button></Link>
            }

            {this.isAllowed('reply') &&
              <Link to={`/community/${this.boardName}/${this.postId}/reply`}><button>답글쓰기</button></Link>
            }
          </div>
        </div>}
      </Loader>
    </Page>;
  }
}

export default BbsView;
