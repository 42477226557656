import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import Pagination from '../components/pagination';
import Loader from '../components/loader';
import server from '../libs/server';
import { stripTags, truncateString, getQueryParam } from '../libs/util';
import SwitchOn from '../images/switch-on.png';
import SwitchOff from '../images/switch-off.png';
import './course-collection.css';

class CourseCollection extends Component {
  state = {
    pagination: { limit: 5, page: this.page, count: 0 },
    entries: [],
    loading: false
  };

  get page() {
    return getQueryParam('page') || 1;
  }

  get activeCoursesOnly() {
    return getQueryParam('active') === 'true';
  }

  componentWillReceiveProps(nextProps) {
    this.loadData(this.page);
  }

  loadData = async (page = this.state.pagination.page) => {
    const limit = this.state.pagination.limit;
    let apiUrl = `/sonsure-courses?offset=${(page - 1) * limit}&limit=${limit}&sort=-id`;

    this.setState({ loading: true });

    if (this.activeCoursesOnly) {
      apiUrl += '&active=true';
    }

    let res = await server.get(apiUrl);

    this.setState({
      loading: false,
      entries: res.data,
      pagination: res.pagination
    });
  }

  renderEntry = entry => {
    let today = new Date();
    let startDate = new Date(entry.startDate);
    let endDate = new Date(entry.endDate);
    let active = true;

    if (today < startDate || today > endDate || (entry.applicationLimit && (entry.applicationCount >= entry.applicationLimit))) {
      active = false;
    }

    return <div key={entry.id} className='course-item row gutter'>
      <div className='course-image col sm-full'>
        <Link to={`/courses/${entry.id}`}>
          <img src={entry.thumbUrl} width={200} height={200} alt={entry.id} />
        </Link>
      </div>

      <div className='course-content col'>
        <div className='course-title'>
          <Link to={`/courses/${entry.id}`}>{entry.title}</Link>
        </div>

        <div className='course-description'>
          {truncateString(stripTags(entry.content), 200)}
        </div>

        <div className='course-table'>
          <dl className='data-table'>
            <dt>접수기간</dt>
            <dd>{entry.startDate} ~ {entry.endDate}</dd>
            <dt>교육기간</dt>
            <dd>{entry.schedule}</dd>
          </dl>
        </div>

        <div className='course-buttons'>
          <Link to={`/courses/${entry.id}`}><button>자세히보기</button></Link>

          {active &&
            <Link to={`/courses/${entry.id}/apply`}><button className='primary'>접수하기</button></Link>
          }

          {!active &&
            <button disabled>접수마감</button>
          }
        </div>
      </div>
    </div>;
  }

  render() {
    let activeCoursesOnly = this.activeCoursesOnly;

    return <Page>
      <PageHeader>교육</PageHeader>
      <Breadcrumb>
        <BreadcrumbItem linkTo='/courses'>교육</BreadcrumbItem>
      </Breadcrumb>

      <div className='course-search-form'>
        {activeCoursesOnly &&
          <Link to='/courses'><img src={SwitchOn} alt='switch off' width={30} height={18} /></Link>
        }

        {!activeCoursesOnly && 
          <Link to='/courses?active=true'><img src={SwitchOff} alt='switch on' width={30} height={18} /></Link>
        }

        <span>접수중인 교육만 보기</span>
      </div>

      <Loader onLoad={this.loadData}>
        {() => <div className='course-list'>
          {this.state.entries.map(entry => this.renderEntry(entry))}

          {this.state.entries.length === 0 &&
            <div className='course-list-empty'>교육이 없습니다.</div>
          }
        </div>}
      </Loader>

      <Pagination {...this.state.pagination}>
        {page => `/courses?page=${page}`}
      </Pagination>
    </Page>;
  }
}

export default CourseCollection;
