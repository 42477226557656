import React, { Component } from 'react';
import { ScrollToTopOnMount } from '../components/scroll-to-top';
import { Link } from 'react-router-dom';

class RegisterComplete extends Component {
  render() {
    let user = this.props.user;

    return <div className='row gutter'>
      <ScrollToTopOnMount />

      <div className='register-help col full'>
        <h3>회원가입이 완료되었습니다.</h3>
        <p>{user.nickname}님 회원가입을 해주셔서 감사합니다! 로그인 페이지를 통해 로그인해주시기 바랍니다.</p>
      </div>

      <div className='register-buttons col full'>
        <Link to='/login'><button className='primary large'>로그인</button></Link>
        <Link to='/'><button className='large'>홈으로</button></Link>
      </div>
    </div>;
  }
}

export default RegisterComplete;
