import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './breadcrumb.css';

export class BreadcrumbItem extends Component {
  render() {
    let { active, linkTo } = this.props;
    let classNames = ['breadcrumb-item'];

    if (active) {
      classNames.push('active');
    }

    return <li className={classNames.join(' ')}>
      {linkTo && 
        <Link to={linkTo}><button>{this.props.children}</button></Link>
      }
      {!linkTo && 
        <button>{this.props.children}</button>
      }
    </li>;
  }
}

export class Breadcrumb extends Component {
  render() {
    return <div className='container'>
      <ul className='breadcrumb'>
        <BreadcrumbItem linkTo='/'>HOME</BreadcrumbItem>
        {this.props.children}
      </ul>
    </div>;
  }
}
