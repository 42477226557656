import React from 'react';
import PropTypes from 'prop-types';
import server from '../libs/server';
import UploadIcon from '../images/upload-icon.png';
import './file-uploader.css';

export class FileContainer {
  files = [];
  storageId = null;
  listeners = [];

  constructor(storageId) {
    this.storageId = storageId;
  }

  listen(callback) {
    if (this.listeners.indexOf(callback) < 0) {
      this.listeners.push(callback);
      callback(this.files);
    }
  }

  notify() {
    for (let callback of this.listeners) {
      callback(this.files);
    }
  }

  getCurrentFiles() {
    return this.files.filter(item => !item.deleted);
  }

  getDeletedFiles() {
    return this.files.filter(item => item.deleted);
  }

  getNewFiles() {
    return this.files.filter(item => item.created);
  }

  getOldFiles() {
    return this.files.filter(item => !item.created && !item.deleted);
  }

  async loadCollection(collectionUrl) {
    let result = await server.get(collectionUrl + '?limit=100');

    this.files = result.data;
    this.notify();
  }

  async applyCollection(collectionUrl) {
    try {
      for (let file of this.files) {
        if (file.created) {
          await server.put(collectionUrl + '/' + file.id);
        } else if (file.deleted) {
          await server.del(collectionUrl + '/' + file.id);
        }
      }
    } catch (e) {
      alert(e.message);
    }
  }

  async uploadFiles(browserFiles) {
    let results = [];

    for (let file of browserFiles) {
      let result = await server.upload(`/storages/${this.storageId}/files`, file);
      let fileResults = await server.get(result.data);

      results.push({ ...fileResults.data, created: true, deleted: false });
    }

    this.files = [...this.files, ...results];
    this.notify();

    return results;
  }

  deleteFiles(deletings) {
    this.files = this.files.map(item => {
      if (deletings.find(p => item.id === p.id)) {
        return { ...item, deleted: true };
      }

      return item;
    });
    this.notify();
  }
}

export class FileUploader extends React.Component {
  static propTypes = {
    fileContainer: PropTypes.instanceOf(FileContainer).isRequired
  };

  state = {
    uploading: false,
    uploadMessage: '',
    items: []
  };

  fileInput = null;

  componentDidMount() {
    this.props.fileContainer.listen(this.onContainerChange);
  }

  onContainerChange = () => {
    let items = this.props.fileContainer.getCurrentFiles();
    this.setState({ items });
  }

  deleteItem = (item) => {
    this.props.fileContainer.deleteFiles([item]);
  }

  onUploadFiles = async e => {
    this.setState({ uploading: true, uploadMessage: '업로드 중...' });

    await this.props.fileContainer.uploadFiles(e.target.files);

    this.setState({ uploading: false });
  }

  onUploadClick = e => {
    e.stopPropagation();
    e.preventDefault();
    this.fileInput.click();
  }

  render() {
    return <div className='file-uploader'>
      {this.state.items.length > 0 &&
        <ul className='file-uploader-list'>
          {this.state.items.map(item => <li key={item.id}>
            <div className='file-uploader-thumbnail' style={{ backgroundImage: `url(${item.thumbUrl})` }} />

            <div className='file-uploader-description'>
              {item.name}
            </div>

            <button className='file-uploader-delete' onClick={() => this.deleteItem(item)}>삭제</button>          
          </li>)}
        </ul>
      }

      {this.state.uploading && 
        <button disabled><span>{this.state.uploadMessage}</span></button>
      }

      {!this.state.uploading &&
        <button className='icon-button' onClick={this.onUploadClick}>
          <img src={UploadIcon} alt='업로드' width={14} height={14} /> <span>파일 추가</span>
        </button>
      }

      <input type='file' ref={fileInput => this.fileInput = fileInput} onChange={this.onUploadFiles} style={{ display: 'none' }} multiple />
    </div>;
  }
}
