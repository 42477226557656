import React, { Component } from 'react';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import Loader from '../components/loader';
import ContentStyle from '../components/content-style';
import server from '../libs/server';

class PageView extends Component {
  state = {
    page: null,
    loading: true
  };

  componentDidMount() {
    this.loadPage(this.props.pageName);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pageName !== nextProps.pageName) {
      this.loadPage(nextProps.pageName);
    }
  }

  loadPage = async (pageName) => {
    this.setState({ loading: true });

    let res = await server.get(`/pages/${pageName}`);

    this.setState({
      page: res.data,
      loading: false
    });
  }

  render() {
    return <Page>
      <ContentStyle />

      {!this.state.loading && <div>
        <PageHeader>{this.state.page.title}</PageHeader>
        <Breadcrumb>
          <BreadcrumbItem linkTo={`/${this.state.page.name}`}>{this.state.page.title}</BreadcrumbItem>
        </Breadcrumb>

        <div className='side-gutter' dangerouslySetInnerHTML={{ __html: this.state.page.content }} />
      </div>}

      <Loader active={this.state.loading} />
    </Page>;
  }
}

export default PageView;
