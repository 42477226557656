import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import server from '../libs/server';

class Authentication extends Component {
  constructor() {
    super();

    this.state = { authenticated: true };
  }

  componentDidMount() {
    server.authenticate().then(authenticated => {
      this.setState({ authenticated });

      if (authenticated && this.props.onSuccess) {
        this.props.onSuccess();
      }
    });
  }

  render() {
    return <div className='authentication'>
      {!this.state.authenticated &&
        <Redirect to={'/login?ret=' + window.location.pathname + window.location.search} />
      }
    </div>;
  }
}

export default Authentication;
