import React, { Component } from 'react';
import { ScrollToTopOnMount } from '../components/scroll-to-top';
import SubHeader from '../components/sub-header';
import { Form, FormField } from '../components/form';
import server from '../libs/server';

const mobileNumbers = [
  '010', '011', '016', '017', '018', '019'
];

const phoneNumbers = [
  '02', '031', '032', '033', '041', '042', '043', '044',
  '051', '052', '053', '054', '055', '061', '062', '063', '064',
  '0502', '0503', '0504', '0505', '0506', '0507', '070',
  '010', '011', '016', '017', '018', '019'
];

class RegisterForm extends Component {
  formElem = null;

  onSubmit = async values => {
    if (values.password !== values.passwordConfirm) {
      throw new Error('비밀번호 확인이 맞지 않습니다. 다시 입력해주세요.');
    }

    try {
      await server.post(`/users/register`, {
        captcha: values.captcha,
        username: values.username,
        nickname: values.nickname,
        password: values.password,
        email: values.email
      });
    } catch (e) {
      if (e.status === 409) {
        throw new Error('이미 존재하는 아이디입니다.');
      } else {
        throw e;
      }
    }

    if (this.props.onNext) {
      this.props.onNext(values);
    }
  }

  render() {
    return <div className='row gutter' ref={elem => this.formElem = elem}>
      <ScrollToTopOnMount />

      <div className='register-help col full'>
        별표(*)가 있는 항목은 필수항목이므로 모두 작성해주시기 바랍니다.
      </div>

      <div className='col full'>
        <Form
          horizontal
          noButtons
          onSubmit={this.onSubmit}
        >
          <SubHeader>필수정보</SubHeader>

          <input name='captcha' />

          <FormField label='아이디' important>
            <input name='username' required minLength={3} maxLength={50} autoFocus /> 
          </FormField>

          <FormField label='비밀번호' important>
            <input type='password' name='password' required minLength={4} /> 
          </FormField>

          <FormField label='비밀번호 확인' important>
            <input type='password' name='passwordConfirm' />
          </FormField>

          <FormField label='이름' important>
            <input name='nickname' required minLength={2} maxLength={50} />
          </FormField>

          <FormField label='이메일' important>
            <input type='email' name='email' required size={30} />
          </FormField>

          {/*
          <SubHeader>추가정보</SubHeader>

          <FormField label='주소'>
            <div className='form-control-row'>
              <input name='postCode' size={8} />
              &nbsp;&nbsp;
              <button>주소 찾기</button>
            </div>
            <div className='form-control-row'>
              <input name='address' size={35} />
            </div>
            <div>
              <input name='addressDetail' size={35} placeholder='나머지 주소를 입력해주세요.' />
            </div>
          </FormField>

          <FormField label='핸드폰'>
            <select name='mobile1'>
              {mobileNumbers.map(v => <option key={v} value={v}>{v}</option>)}
            </select>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <input name='mobile2' size={4} maxLength={4} />
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <input name='mobile3' size={4} maxLength={4} />
          </FormField>

          <FormField label='일반전화'>
            <select name='phone1'>
              {phoneNumbers.map(v => <option key={v} value={v}>{v}</option>)}
            </select>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <input name='mobile2' size={4} maxLength={4} />
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <input name='mobile3' size={4} maxLength={4} />
          </FormField>

          <FormField label='성별'>
            <label><input type='radio' name='gender' value='MALE' /> <span>남자</span></label>
            &nbsp;&nbsp;
            <label><input type='radio' name='gender' value='FEMALE' /> <span>여자</span></label>
          </FormField>

          <FormField label='생년월일'>
            <input type='number' name='birtyYear' min={1900} max={2017} />
            &nbsp;&nbsp;년&nbsp;&nbsp;
            <input type='number' name='birtyMonth' min={1} max={12} />
            &nbsp;&nbsp;월&nbsp;&nbsp;
            <input type='number' name='birtyDay' min={1} max={31} />
            &nbsp;&nbsp;일&nbsp;&nbsp;&nbsp;&nbsp;
            <label><input type='radio' name='birthCalendar' value='LUNAR' /> <span>음력</span></label>
            &nbsp;&nbsp;
            <label><input type='radio' name='birthCalendar' value='SOLAR' /> <span>양력</span></label>
          </FormField>
          */}

          <div className='register-buttons'>
            <button className='primary large'>회원가입</button>
          </div>
        </Form>
      </div>
    </div>;
  }
}

export default RegisterForm;
