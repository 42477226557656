import React, { Component } from 'react';
import TopNav from './top-nav';
import TopLogo from './top-logo';
import TopMenu from './top-menu';
import Footer from './footer';
import server from '../libs/server';
import './page.css';

export default class Page extends Component {
  componentDidMount() {
    server.post(`/web-visitor-tracks`);
  }

  render() {
    return <div className='page'>
      <TopNav />
      <TopLogo />
      <TopMenu />

      <div className='container'>
        {this.props.children}
      </div>

      <Footer />
    </div>;
  }
}
