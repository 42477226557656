import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import SubHeader from '../components/sub-header';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import { Form, FormField, FormText } from '../components/form';
import Loader from '../components/loader';
import server from '../libs/server';

class CourseApply extends Component {
  loggedIn = false;

  get courseId() {
    return this.props.match.params.courseId;
  }

  loadData = async () => {
    let res = await server.get(`/sonsure-courses/${this.courseId}`);
    this.loggedIn = await server.authenticate();
    return res.data;
  }

  onSubmit = async (values) => {
    if (!values.agreement) {
      throw new Error('이용약관 및 개인정보취급방침에 반드시 동의해주세요.');
    }

    await server.post(`/sonsure-courses/${this.courseId}/sonsure-course-applications`, {
      name: values.name,
      phone: values.phone,
      message: values.message || '교육신청합니다.',
      password: values.password
    });
  }

  isActive = course => {
    let today = new Date();
    let startDate = new Date(course.startDate);
    let endDate = new Date(course.endDate);
    let active = true;

    if (today < startDate || today > endDate || (course.applicationLimit && (course.applicationCount >= course.applicationLimit))) {
      active = false;
    }

    return active;
  }

  renderForm = course => {
    let active = this.isActive(course);

    return <div className='side-gutter'>
      <SubHeader>교육 신청</SubHeader>

      {active &&
        <Form
          onSubmit={this.onSubmit}
          redirectTo={`/courses/${this.courseId}`}
          cancelButton='취소'
          horizontal
        >
          <input className='captcha' name='captcha' />

          <FormField label='주제'>
            <FormText>{course.title}</FormText>
          </FormField>

          <FormField label='교육일정'>
            <FormText>{course.schedule}</FormText>
          </FormField>

          <FormField label='이름'>
            <input name='name' size={20} maxLength={50} placeholder='이름을 입력하세요.' required autoFocus />
          </FormField>

          <FormField label='연락처'>
            <input name='phone' size={30} maxLength={20} placeholder='연락처를 입력하세요.' required />
          </FormField>

          <FormField label={course.messageLabel || '남기실 메시지'}>
            <textarea name='message' cols={50} rows={3} maxLength={100} />
          </FormField>

          {!this.loggedIn &&
            <FormField label='비밀번호'>
              <input type='password' name='password' minLength={4} required />
            </FormField>
          }
          
          <FormField>
            <label><input type='checkbox' name='agreement' /> <Link to='/access-terms'>이용약관</Link> 및 <Link to='/privacy-terms'>개인정보취급방침</Link>에 동의합니다.</label>
          </FormField>
        </Form>
      }

      {!active &&
        <div className='error'>
          접수기간이 아닙니다. <Link to='/courses'><button>목록으로</button></Link>
        </div>
      }
    </div>;
  }

  render() {
    return <Page>
      <PageHeader>교육</PageHeader>
      <Breadcrumb>
        <BreadcrumbItem linkTo='/courses'>교육</BreadcrumbItem>
      </Breadcrumb>

      <Loader onLoad={this.loadData}>
        {this.renderForm}
      </Loader>
    </Page>;
  }
}

export default CourseApply;
