
class Pagination {
  constructor(headers) {
    this.limit = Number(headers.get('X-Pagination-Limit'));
    this.count = Number(headers.get('X-Pagination-Count'));
    this.page = Number(headers.get('X-Pagination-Page'));
  }
}

class Server {
  apiBase = process.env.REACT_APP_API_BASE;
  user = null;
  authToken = null;
  requestCache = {};

  constructor() {
    this.loadState();
  }

  async request(method, url, reqData) {
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');

    if (this.authToken) {
      headers.append('Authorization', 'Bearer ' + this.authToken);
    }

    let res = null;

    try {
      res = await fetch(this.apiBase + url, {
        method,
        mode: 'cors',
        headers,
        body: reqData ? JSON.stringify(reqData) : undefined
      });
    } catch (e) {
      console.log(e)
      throw(new Error('서버와의 연결이 원활하지 않습니다.'));
    }

    let contentType = res.headers.get('Content-Type') || '';

    if (res.status === 200) {
      let data = contentType.indexOf('json') > 0 ? await res.json() : await res.text();
      let pagination = res.headers.has('X-Pagination-Page') ? new Pagination(res.headers) : null;

      return {
        data,
        response: res,
        pagination
      };
    } else if (res.status >= 400) {
      let data = contentType.indexOf('json') > 0 ? await res.json() : await res.text();
      throw(data);
    } else {
      let data = contentType.indexOf('json') > 0 ? await res.json() : await res.text();

      return {
        data,
        response: res,
        pagination: null
      };
    }
  }

  async upload(url, file) {
    let headers = new Headers();
    let formData = new FormData();

    if (this.authToken) {
      headers.append('Authorization', 'Bearer ' + this.authToken);
    }

    formData.append('file', file, file.name);

    try {
      let res = await fetch(this.apiBase + url, {
        method: 'POST',
        mode: 'cors',
        body: formData,
        headers
      });

      if (res.status >= 400) {
        let data = res.headers.get('Content-Type').indexOf('json') > 0 ? await res.json() : await res.text();
        throw(data);
      }

      return {
        data: await res.text(),
        response: res,
        pagination: null
      };
    } catch (e) {
      throw(new Error(e.message ? e.message : '서버와의 연결이 원활하지 않습니다.'));
    }
  }
 
  async get(...args) {
    return await this.request('GET', ...args);
  }

  async post(...args) {
    return await this.request('POST', ...args);
  }

  async put(...args) {
    return await this.request('PUT', ...args);
  }

  async del(...args) {
    return await this.request('DELETE', ...args);
  }

  async cached(url) {
    let cached = this.requestCache[url];

    if (!cached) {
      this.requestCache[url] = cached = {
        url,
        data: null,
        pending: false,
        success: false,
        fail: false,
        awaits: []
      };
    }

    if (cached.success) {
      return cached.data;
    }

    if (cached.pending) {
      return new Promise((resolve, reject) => {
        cached.awaits.push({resolve, reject});
      });
    }

    cached.pending = true;

    try {
      const data = await this.request('GET', url);

      cached.data = data;
      cached.success = true;
      cached.fail = false;
      cached.pending = false;

      for (let p of cached.awaits) {
        p.resolve(data);
      }

      cached.awaits = [];

      return data;
    } catch (e) {
      cached.data = e;
      cached.success = false;
      cached.fail = true;
      cached.pending = false;

      for (let p of cached.awaits) {
        p.reject(e);
      }

      cached.awaits = [];

      throw (e);
    }
  }

  async logIn({ username, password }) {
    let authToken = (await this.post('/auth', { username, password })).data;

    this.authToken = authToken;
    this.user = (await this.get('/me')).data;

    this.saveCurrentState();

    return this.user;
  }

  async authenticate(authToken = this.authToken) {
    if (!authToken) {
      return false;
    }

    const modified = authToken !== this.authToken;

    this.authToken = authToken;

    try {
      await this.get('/auth/validate');

      if (modified) {
        this.user = (await this.get('/me')).data;
        this.saveCurrentState();
      }

      return true;
    } catch (e) {
      this.logOut();
      return false;
    }
  }

  logOut() {
    localStorage.setItem('authToken', '');
    localStorage.setItem('user', '');

    this.user = null;
    this.authToken = null;
  }

  saveCurrentState() {
    if (this.user) {
      localStorage.setItem('authToken', this.authToken);
      localStorage.setItem('user', JSON.stringify(this.user));
    }
  }

  loadState() {
    let authToken = localStorage.getItem('authToken');

    if (authToken) {
      let user = localStorage.getItem('user');

      this.authToken = authToken;
      this.user = JSON.parse(user);
    }
  }

  getSecureUserName(user) {
    if (this.user && this.user.id === user.id) {
      return user.nickname;
    }

    return user.nickname[0] + '***';
  }
}

export default new Server();
