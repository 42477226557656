import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import SubHeader from '../components/sub-header';
import { Form, FormField } from '../components/form';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import server from '../libs/server';

class FindUsername extends Component {
  state = {
    email: null,
    sended: false
  };

  onSubmit = async values => {
    try {
      await server.post(`/users/username/request`, values);

      this.setState({ 
        email: values.email,
        sended: true
      });
    } catch (e) {
      if (e.status === 404) {
        throw new Error('해당하는 이메일이 없습니다.');
      } else {
        throw e;
      }
    }
  }

  render() {
    if (server.user) {
      return <Redirect to='/' />;
    }

    return <Page>
      <PageHeader>아이디 찾기</PageHeader>
      <Breadcrumb>
        <BreadcrumbItem linkTo={`/find-username`}>아이디 찾기</BreadcrumbItem>
      </Breadcrumb>

      <div className='row gutter'>
        {!this.state.sended &&
          <div className='col full'>
            <div className='page-help'>가입시 작성한 이메일을 입력해주세요.</div>

            <SubHeader>이메일 입력</SubHeader>

            <Form horizontal onSubmit={this.onSubmit}>
              <input name='captcha' />

              <FormField label='이메일'>
                <input type='email' name='email' size={30} required autoFocus />
              </FormField>
            </Form>
          </div>
        }
      
        {this.state.sended &&
          <div className='col full'>
            <div className='page-help'>
              <p>가입하신 이메일(<strong>{this.state.email}</strong>)로 아이디를 발송하였습니다.</p>
              <p>
                <Link to='/login'><button className='primary large'>로그인</button></Link>
                <Link to='/'><button className='large'>홈으로</button></Link>
              </p>
            </div>
          </div>
        }
      </div>
    </Page>;
  }
}

export default FindUsername;
