import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import ScrollToTop from './components/scroll-to-top';
import PageNotFound from './pages/page-not-found';
import Home from './pages/home';
import Login from './pages/login';
import Logout from './pages/logout';
import LoginCallback from './pages/login-callback';
import PageView from './pages/page-view';
import Register from './pages/register';
import RegisterActivation from './pages/register-activation';
import FindUsername from './pages/find-username';
import ResetPassword from './pages/reset-password';
import ResetPasswordComplete from './pages/reset-password-complete';
import AboutMap from './pages/about-map';
import About from './pages/about';
import Activity from './pages/activity';
import CourseCollection from './pages/course-collection';
import CourseView from './pages/course-view';
import CourseApply from './pages/course-apply';
import CourseCancel from './pages/course-cancel';
import Shop from './pages/shop';
import BbsList from './pages/bbs-list';
import BbsView from './pages/bbs-view';
import BbsWrite from './pages/bbs-write';
import BbsModify from './pages/bbs-modify';
import BbsReply from './pages/bbs-reply';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/login' exact component={Login} />
            <Route path='/logout' exact component={Logout} />
            <Route path='/register' exact component={Register} />
            <Route path='/register/activate/:code' exact component={RegisterActivation} />
            <Route path='/find-username' exact component={FindUsername} />
            <Route path='/reset-password' exact component={ResetPassword} />
            <Route path='/reset-password/:code' exact component={ResetPasswordComplete} />
            <Route path='/login/callback' exact component={LoginCallback} />

            <Route path='/community/:boardName' exact component={BbsList} />
            <Route path='/community/:boardName/write' exact component={BbsWrite} />
            <Route path='/community/:boardName/:postId' exact component={BbsView} />
            <Route path='/community/:boardName/:postId/modify' exact component={BbsModify} />
            <Route path='/community/:boardName/:postId/reply' exact component={BbsReply} />

            <Route path='/about' exact render={() => <About pageName='about-sonsure' />} />
            <Route path='/about-values' exact render={() => <About pageName='about-values' />} />
            <Route path='/about-activities' exact render={() => <About pageName='about-activities' />} />
            <Route path='/about-relationships' exact render={() => <About pageName='about-relationships' />} />
            <Route path='/about-map' exact component={AboutMap} />

            <Route path='/activity' exact component={Activity} />
            <Route path='/courses' exact component={CourseCollection} />
            <Route path='/courses/:courseId' exact component={CourseView} />
            <Route path='/courses/:courseId/apply' component={CourseApply} />
            <Route path='/course-applications/:applicationId/cancel' component={CourseCancel} />

            <Route path='/shop' exact component={Shop} />

            <Route path='/access-terms' exact render={() => <PageView pageName='access-terms' />} />
            <Route path='/privacy-terms' exact render={() => <PageView pageName='privacy-terms' />} />

            <Route component={PageNotFound} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
