import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SubHeader from '../components/sub-header';
import { Form } from '../components/form';
import Loader from '../components/loader';
import server from '../libs/server';
import { stripTags } from '../libs/util';

class RegisterAgreement extends Component {
  formElem = null;

  loadPage = async (pageName) => {
    let res = await server.get(`/pages/${pageName}`);
    return res.data;
  }

  onNextClick = e => {
    e.stopPropagation();
    e.preventDefault();

    let values = Form.getFormValues(this.formElem);

    if (!values.accessTermsAgree || !values.privacyTermsAgree) {
      alert('이용약관과 개인정보취급방침에 모두 동의하여 주시기 바랍니다.');
    } else if (this.props.onNext) {
      this.props.onNext();
    }
  }

  render() {
    return <div className='row gutter' ref={elem => this.formElem = elem}>
      <div className='register-help col full'>
        회원가입을 진행하기 위해, 아래의 <strong>이용약관</strong> 및 <strong>개인정보취급방침</strong> 약관을 모두 읽고 동의하여 주시기 바랍니다.
      </div>

      <div className='register-terms col full'>
        <SubHeader>이용약관</SubHeader>
        <Loader onLoad={this.loadPage} defaultArguments={['access-terms']}>
          {page => <textarea className='full-width' rows={10} defaultValue={stripTags(page.content)} readOnly />}
        </Loader>
        <label><input type='checkbox' name='accessTermsAgree' /> <span>이용약관에 동의합니다.</span></label>
      </div>
      
      <div className='register-terms col full'>
        <SubHeader>개인정보취급방침</SubHeader>
        <Loader onLoad={this.loadPage} defaultArguments={['privacy-terms']}>
          {page => <textarea className='full-width' rows={10} defaultValue={stripTags(page.content)} readOnly />}
        </Loader>
        <label><input type='checkbox' name='privacyTermsAgree' /> <span>개인정보취급방침에 동의합니다.</span></label>
      </div>

      <div className='register-buttons col full'>
        <button className='primary large' onClick={this.onNextClick}>다음단계</button>
        <Link to='/'><button className='large'>취소하기</button></Link>
      </div>
    </div>;
  }
}

export default RegisterAgreement;
