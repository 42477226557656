import React, { Component } from 'react';
import Authentication from '../components/authentication';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import { Form, FormField } from '../components/form';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import { FileContainer, FileUploader } from '../components/file-uploader';
import TinyEditor from '../components/tiny-editor';
import Loader from '../components/loader';
import server from '../libs/server';
import './bbs-write.css';
import boardInfo from '../bbs-board-info.json';

class BbsWrite extends Component {
  get boardName() {
    return this.props.match.params.boardName;
  }

  get boardId() {
    return boardInfo[this.boardName].id;
  }

  isAllowed(featureName) {
    return boardInfo[this.boardName].allows.indexOf(featureName) >= 0;
  }

  loadCategories = async (boardId = this.boardId) => {
    let res = await server.get(`/bulletin-boards/${boardId}/bulletin-post-categories?limit=100`);
    return res.data;
  }

  loadBoard = async (boardId = this.boardId) => {
    let res = await server.get(`/bulletin-boards/${boardId}`);
    let fileContainer = null;

    if (this.isAllowed('upload') && res.data.storageId) {
      fileContainer = new FileContainer(res.data.storageId);
    }

    return {
      board: res.data,
      fileContainer
    };
  }

  onSubmit = async (values, board, fileContainer) => {
    let res = await server.post(`/bulletin-boards/${board.id}/bulletin-posts`, {
      captcha: values.captcha,
      title: values.title,
      content: values.content,
      secret: values.secret,
      categoryId: Number(values.categoryId) || undefined
    });

    await fileContainer.applyCollection(res.data + '/files');
  }

  renderForm({ board, fileContainer }) {
    return <div className='bbs-write-form side-gutter'>
      <Form
        redirectTo={`/community/${this.boardName}`}
        onSubmit={values => this.onSubmit(values, board, fileContainer)}
      >
        <input name='captcha' />

        <Loader onLoad={this.loadCategories}>
          {categories => {
            if (categories.length > 0) {
              return <FormField label='카테고리'>
                <select name='categoryId'>
                  <option value={0}> </option>
                  {categories.map(item => <option key={item.id} value={item.id}>{item.title}</option>)}
                </select>
              </FormField>;
            } else {
              return <div></div>;
            }
          }}
        </Loader>

        <FormField label='제목'>
          <input type='text' name='title' className='full-width' placeholder='제목을 입력하세요.' required autoFocus />
        </FormField>

        <FormField label='내용'>
          <TinyEditor
            id='tiny-editor-1'
            name='content'
            allowTextStyle={this.isAllowed('html')}
            allowImage={!!fileContainer}
            contentCss={`${server.apiBase}/content-styles/${board.contentStyleId}/style.css`}
            fileContainer={fileContainer}
          />
        </FormField>

        {!!fileContainer &&
          <FormField label='파일'>
            <FileUploader fileContainer={fileContainer} />
          </FormField>
        }

        <FormField>
          <label><input type='checkbox' name='secret' /> <span>비밀글</span></label>
        </FormField>
      </Form>
    </div>;
  }

  render() {
    return <Page>
      <Authentication />
      <Loader onLoad={this.loadBoard}>
        {data => <div>
          <PageHeader>{data.board.title}</PageHeader>
          <Breadcrumb>
            <BreadcrumbItem linkTo={`/community/${this.boardName}`}>{data.board.title}</BreadcrumbItem>
          </Breadcrumb>

          {this.renderForm(data)}
        </div>}
      </Loader>
    </Page>;
  }
}

export default BbsWrite;
