
/**
 * 파일 크기를 읽기 쉬운 형태로 반환한다.
 * @see http://stackoverflow.com/a/18650828
 * @param {number} bytes 바이트 수.
 * @param {number} decimals 소수점 몇 자리까지 출력할 것인지?
 * @return {string}
 */
export function formatBytes(bytes, decimals) {
  if (bytes === 0) {
    return '0 Byte';
  }

  let k = 1000; // or 1024 for binary
  let dm = decimals + 1 || 1;
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
}

/**
 * 문자열이 주어진 길이 이상이라면 생략부호를 붙여 줄인다.
 */
export function truncateString(str, limit, ellipsis = '…') {
  let t = str.toString();
  let b = 0;
  let c = 0;
  let i = 0;

  // 문자열의 바이트 길이를 그대로 구하는 대신, 2바이트가 넘는 경우 무조건 2바이트로 간주함.
  // 영문자 외에 2바이트가 넘어가는 경우가 많기 때문에 바이트 수로 하면 적정한 길이를 맞추지 못함.
  while (t.charCodeAt(i)) {
    c = t.charCodeAt(i);
    b += c >> 7 ? 2 : 1;
    i++;
  }

  if (b > limit) {
    let byteLimit = Math.floor(limit * (t.length / b));

    t = t.substring(0, byteLimit - ellipsis.length) + ellipsis;
  }

  return t;
}

/**
 * 숫자를 통화 형식으로 표현하여 반환한다.
 * 예) 1024 => 1,024
 * @param {number} value 통화로 표시할 숫자
 * @param {number} decimals 표시할 소수점 자리수
 */
export function formatCurrency(value, decimals = 0) {
  let str = Number(value).toFixed(decimals);

  if (decimals) {
    return str.replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  } else {
    return str.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
}

/**
 * HTML 문자열에서 HTML 태그만 선택적으로 삭제함.
 */
export function stripTags(html) {
  return html.replace(/<[^>]+>|&\w+;/ig, '');
}

/**
 * 쿼리스트링에서 특정값을 가져옴.
 */
export function getQueryParam(name) {
  let regex = new RegExp(name + '=([^&]*)', 'i');
  let q = window.location.search.match(regex);

  if (q) {
    return Number(q[1]) || q[1];
  }

  return undefined;
}

/**
 * 주어진 날짜를 오늘 날짜와 비교하여 얼마나 지났는지를 표시한다.
 * @param {Date} date 
 */
export function getPastDate(date) {
  let today = new Date();
  date = new Date(date);
  let diff = Math.ceil((today.getTime() - date.getTime()) / 1000);

  if (diff < 60) {
    return '방금 전';
  }

  if (diff < 3600) {
    return Math.ceil(diff / 60) + '분 전';
  }

  if (diff < 3600 * 12) {
    return Math.ceil(diff / 3600) + '시간 전';
  }

  if (diff < 3600 * 24 * 2) {
    if (date.getDate() === today.getDate()) {
      return '오늘';
    } else {
      return '어제';
    }
  }

  if (diff < 3600 * 24 * 3) {
    return '이틀 전';
  }

  if (diff < 3600 * 24 * 7) {
    return Math.ceil(diff / 3600 / 24) + '일 전';
  }

  if (diff < 3600 * 24 * 30) {
    return Math.floor(diff / 3600 / 24 / 7) + '주 전';
  }

  if (diff < 3600 * 24 * 30 * 2) {
    return '한달 전';
  }

  if (diff < 3600 * 24 * 365) {
    return Math.ceil(diff / 3600 / 24 / 30) + '달 전';
  }

  return Math.ceil(diff / 3600 / 24 / 365) + '년 전';
}

/**
 * MS가 만든 브라우저는 하나같이 버그 투성이임...
 */
export function detectMSBrowser() {
  return document.documentMode || /Edge/.test(navigator.userAgent);
}
