import React, { Component } from 'react';
import './sub-header.css';

export default class SubHeader extends Component {
  render() {
    return <div className='sub-header'>
      {this.props.children}
    </div>;
  }
}
