import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import Pagination from '../components/pagination';
import Loader from '../components/loader';
import server from '../libs/server';
import { getQueryParam } from '../libs/util';
import BbsListTable from './bbs-list-table';
import BbsListCard from './bbs-list-card';
import BbsListMedia from './bbs-list-media';
import './bbs-list.css';
import PencilIcon from '../images/pencil-icon.png';
import boardInfo from '../bbs-board-info.json';

class BbsList extends Component {
  state = {
    pagination: { page: this.page, limit: 12, offset: 0 },
    entries: [],
    board: null,
    categories: [],
    loading: true
  };

  get page() {
    return getQueryParam('page') || 1;
  }

  get categoryId() {
    return getQueryParam('cat') || 0;
  }

  get boardName() {
    return this.props.match.params.boardName;
  }

  get boardType() {
    return boardInfo[this.boardName].type;
  }

  isAllowed(featureName) {
    return boardInfo[this.boardName].allows.indexOf(featureName) >= 0;
  }

  componentDidMount() {
    let boardName = this.props.match.params.boardName;
    let boardId = boardInfo[boardName].id;

    this.loadData(boardId);
  }

  componentWillReceiveProps(nextProps) {
    let boardName = nextProps.match.params.boardName;
    let boardId = boardInfo[boardName].id;

    this.loadData(boardId);
  }

  loadBoard = async (boardId) => {
    let res = await server.get(`/bulletin-boards/${boardId}`);
    this.setState({ board: res.data });
  }

  loadCategories = async (boardId) => {
    let res = await server.get(`/bulletin-boards/${boardId}/bulletin-post-categories?limit=100`);
    this.setState({ categories: res.data });
  }

  loadEntries = async (boardId) => {
    const limit = this.state.pagination.limit;
    const offset = (this.page - 1) * limit;
    let res = await server.get(`/bulletin-boards/${boardId}/bulletin-posts?offset=${offset}&limit=${limit}${this.categoryId ? '&categoryId=' + this.categoryId : ''}`);
    this.setState({
      entries: res.data,
      pagination: res.pagination
    });
  }

  loadData = async (boardId) => {
    this.setState({ loading: true });

    try {
      if (!this.state.board || this.state.board.id !== boardId) {
        await this.loadBoard(boardId);
        await this.loadCategories(boardId);
      }

      await this.loadEntries(boardId);
    } catch (e) {
      alert(e.message);
    }

    this.setState({ loading: false });
  }

  getPageUrl(page = this.page, categoryId = this.categoryId) {
    let url = `/community/${this.boardName}`;
    let queries = [];
    
    if (page > 1) {
      queries.push('page=' + page);
    }

    if (Number(categoryId)) {
      queries.push('cat=' + categoryId);
    }

    return url + '?' + queries.join('&');
  }

  onCategoryChange = categoryId => {
    this.props.history.push(this.getPageUrl(1, categoryId));
  }

  renderPostList() {
    switch (this.boardType) {
      case 'card': return <BbsListCard boardName={this.boardName} entries={this.state.entries} />;
      case 'media': return <BbsListMedia boardName={this.boardName} entries={this.state.entries} />;
      default: return <BbsListTable boardName={this.boardName} entries={this.state.entries} />;
    }
  }

  renderSearchForm() {
    return <div className='bbs-search-form'>
      {this.state.categories.length > 0 &&
        <select
          className='category-select'
          value={this.categoryId}
          onChange={e => this.onCategoryChange(e.target.value)}
        >
          <option key={0} value={0}>전체보기</option>

          {this.state.categories.map(item =>
            <option key={item.id} value={item.id}>{item.title}</option>
          )}
        </select>
      }

      {this.isAllowed('write') &&
        <Link to={`/community/${this.boardName}/write`}><button className='icon-button'><img src={PencilIcon} alt='글쓰기' width={14} height={14} /> <span>글쓰기</span></button></Link>
      }
    </div>;
  }

  render() {
    return <Page>
      {!!this.state.board && <div>
        <PageHeader>{this.state.board.title}</PageHeader>
        <Breadcrumb>
          <BreadcrumbItem linkTo={this.getPageUrl(1, 0)}>{this.state.board.title}</BreadcrumbItem>
        </Breadcrumb>
      </div>}

      {this.renderSearchForm()}

      <div className='bbs-post-list'>
        {this.renderPostList()}
      </div>

      {this.state.entries.length === 0 &&
        <div className='bbs-post-list-empty'>글이 없습니다.</div>
      }

      {this.renderSearchForm()}

      <Pagination {...this.state.pagination}>
        {page => this.getPageUrl(page) }
      </Pagination>

      <Loader active={this.state.loading} />
    </Page>;
  }
}

export default BbsList;
