import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getPastDate, truncateString } from '../libs/util';
import './bbs-list-card.css';
import CommentIcon from '../images/comment-icon.png';
import LockIcon from '../images/lock-icon.png';

class BbsListCard extends Component {
  renderItem(entry) {
    return <div key={entry.id} className='col quarter md-one-third sm-half'>
      <div className='bbs-list-card-item'>
        {!!entry.category &&
          <span className='bbs-list-card-category-badge'>{entry.category.title}</span>
        }

        <div className='bbs-list-card-thumbnail'>
          <Link to={`/community/${this.props.boardName}/${entry.id}`}>
            <img src={entry.thumbUrl} alt={entry.id} />
          </Link>
        </div>

        <div className='bbs-list-card-title'>
          <Link to={`/community/${this.props.boardName}/${entry.id}`}>
            <span>{truncateString(entry.title, 30)}</span>
          </Link>

          {entry.secret &&
            <span className='bbs-list-card-secret'>
              <img src={LockIcon} alt='비밀글' width={16} height={16} />
            </span>
          }
        </div>
        <div className='bbs-list-card-description'>
          <span className='bbs-list-card-author'>{entry.author.nickname}</span>
          <span className='bbs-list-card-date'><span>{getPastDate(entry.createdAt)}</span></span>

          {entry.commentCount > 0 &&
            <span className='bbs-list-card-comment-count'>
              <img src={CommentIcon} alt='댓글' width={18} height={14} />
              <span>{entry.commentCount}</span>
            </span>
          }
        </div>
      </div>
    </div>;
  }

  render() {
    return <div className='row gutter'>
      {this.props.entries.map(entry => this.renderItem(entry))}
    </div>;
  }
}

export default BbsListCard;
