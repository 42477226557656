import React, { Component } from 'react';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import SubHeader from '../components/sub-header';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import { Form, FormField, FormText } from '../components/form';
import Loader from '../components/loader';
import server from '../libs/server';

class CourseCancel extends Component {
  loggedIn = false;

  get applicationId() {
    return this.props.match.params.applicationId;
  }

  loadCourse = async (courseId) => {
    let res = await server.get(`/sonsure-courses/${courseId}`);
    return res.data;
  }

  loadApplication = async (applicationId) => {
    let res = await server.get(`/sonsure-course-applications/${applicationId}`);
    return res.data;
  }

  loadData = async () => {
    let application = await this.loadApplication(this.applicationId);
    let course = await this.loadCourse(application.courseId);

    this.loggedIn = await server.authenticate();

    if ((this.loggedIn && server.user.id !== application.userId) || (!this.loggedIn && application.userId)) {
      throw new Error('타인의 교육 신청을 취소할 수 없습니다.');
    }

    return {
      application,
      course
    };
  }

  onSubmit = async (values) => {
    await server.post(`/sonsure-course-applications/${this.applicationId}/cancel`, values);
    alert('교육 신청이 취소되었습니다.');
  }

  renderForm = ({ course, application }) => {
    return <div className='side-gutter'>
      <SubHeader>교육 신청 취소</SubHeader>

      <p>교육 신청을 취소합니다. 홈페이지에서의 재신청은 불가능하므로 신중히 생각해주세요.</p>

      <Form
        onSubmit={this.onSubmit}
        redirectTo={`/courses/${course.id}`}
        submitButton='신청 취소'
        cancelButton
        horizontal
      >
        <input className='captcha' name='captcha' />

        <FormField label='주제'>
          <FormText>{course.title}</FormText>
        </FormField>

        <FormField label='교육일정'>
          <FormText>{course.schedule}</FormText>
        </FormField>

        <FormField label='이름'>
          <FormText>{application.name}</FormText>
        </FormField>

        {!this.loggedIn &&
          <FormField label='비밀번호'>
            <input type='password' name='password' minLength={4} required />
          </FormField>
        }
      </Form>
    </div>;
  }

  render() {
    return <Page>
      <PageHeader>교육</PageHeader>
      <Breadcrumb>
        <BreadcrumbItem linkTo='/course'>교육</BreadcrumbItem>
      </Breadcrumb>

      <Loader onLoad={this.loadData}>
        {this.renderForm}
      </Loader>
    </Page>;
  }
}

export default CourseCancel;
