import React, { Component } from 'react';
import './page-header.css';

export default class PageHeader extends Component {
  render() {
    return <div className='page-header'>
      <div className='container'>
        {this.props.children}
      </div>
    </div>;
  }
}
