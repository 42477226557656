import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './top-menu.css';
import boardInfo from '../bbs-board-info.json';
import SearchIcon from '../images/search-icon.png';
import BrandLogo from '../images/top-logo.png';

export default class TopMenu extends Component {
  static defaultProps = {
    stickyOffset: 150
  };

  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      sticky: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('touchmove', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('touchmove', this.onScroll);
  }

  componentWillReceiveProps() {
    this.setState({ collapsed: true });
  }

  onScroll = (e) => {
    if (window.pageYOffset >= this.props.stickyOffset) {
      this.setState({ sticky: true });
    } else {
      this.setState({ sticky: false });
    }
  }

  onMenuButtonClick = e => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  renderSearchBox() {
    return <div className='top-menu-search-box'>
      <form>
        <input type='text' placeholder='검색' />
        <button><img src={SearchIcon} alt='검색' width={14} height={14} /></button>
      </form>
    </div>;
  }

  renderMenuButton() {
    return <button className='top-menu-button sm-shown' onClick={this.onMenuButtonClick}>
      <span> </span>
      <span> </span>
      <span> </span>
    </button>;
  }

  render() {
    let classNames = ['top-menu'];

    if (this.state.sticky) {
      classNames.push('sticky');
    }

    if (this.state.collapsed) {
      classNames.push('collapsed');
    }

    return <div className={classNames.join(' ')}>
      <div className='top-menu-container'>
        {this.renderMenuButton()}

        <div className='container'>
          <div className='top-menu-logo sm-shown'>
            <img src={BrandLogo} alt='sonsure.co.kr' width={120} height={96} />
          </div>

          {this.renderSearchBox()}

          <ul className='top-menu-items'>
            <li className='top-menu-item'><Link to='/about'>소개</Link></li>
            <li className='top-menu-item'><Link to='/activity'>활동</Link></li>
            <li className='top-menu-item'><Link to='/courses'>교육</Link></li>
            <li className='top-menu-item'>
              <Link to='/community/journey'>커뮤니티</Link>
              <ul className='top-menu-sub-menu'>
                {Object.keys(boardInfo).map(boardName => 
                  <li key={boardName} className='top-menu-sub-item'><Link to={`/community/${boardName}`}>{boardInfo[boardName].name}</Link></li>
                )}
              </ul>
            </li>
            <li className='top-menu-item'><Link to='/shop'>장터</Link></li>
          </ul>
        </div>
      </div>
    </div>;
  }
}
