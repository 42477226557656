import React, { Component } from 'react';
import './badge.css';

export default class Badge extends Component {
  render() {
    let classNames = ['badge'];

    return <span className={classNames.join(' ')}>
      {this.props.children}
    </span>;
  }
}
