import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getPastDate, truncateString } from '../libs/util';
import './bbs-list-media.css';
import CommentIcon from '../images/comment-icon.png';
import LockIcon from '../images/lock-icon.png';
import TimeIcon from '../images/time-icon.png';

class BbsListMedia extends Component {
  renderItem(entry) {
    return <div key={entry.id} className='col full'>
      <div className='bbs-list-media-item'>
        {!!entry.category &&
          <span className='bbs-list-media-category-badge'>{entry.category.title}</span>
        }

        <div className='bbs-list-media-thumbnail'>
          <Link to={`/community/${this.props.boardName}/${entry.id}`}>
            <img src={entry.thumbUrl} alt={entry.id} />
          </Link>
        </div>

        <div className='bbs-list-media-title'>
          <Link to={`/community/${this.props.boardName}/${entry.id}`}>
            {truncateString(entry.title, 100)}
          </Link>

          {entry.secret &&
            <span className='bbs-list-media-secret'>
              <img src={LockIcon} alt='비밀글' width={16} height={16} />
            </span>
          }
        </div>

        <div className='bbs-list-media-excerpt'>
          <Link to={`/community/${this.props.boardName}/${entry.id}`}>
            {entry.excerpt}
          </Link>
        </div>

        <div className='bbs-list-media-description'>
          <span className='bbs-list-media-author'>{entry.author.nickname}</span>
          <span className='bbs-list-media-date'>
            <img src={TimeIcon} alt='작성일' width={14} height={14} />
            {getPastDate(entry.createdAt)}
          </span>

          {entry.commentCount > 0 &&
            <span className='bbs-list-media-comment-count'>
              <img src={CommentIcon} alt='댓글' width={18} height={14} />
              {entry.commentCount}
            </span>
          }
        </div>
      </div>
    </div>;
  }

  render() {
    return <div className='row gutter'>
      {this.props.entries.map(entry => this.renderItem(entry))}
    </div>;
  }
}

export default BbsListMedia;
