import React, { Component } from 'react';
import './stepper.css';

export class Step extends Component {
  render() {
    let classNames = ['step'];

    if (this.props.active) {
      classNames.push('active');
    }

    return <li className={classNames.join(' ')}>
      {this.props.children}
    </li>;
  }
}

export class Stepper extends Component {
  render() {
    return <ul className='stepper'>
      {this.props.children}
    </ul>;
  }
}
