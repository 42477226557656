import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Badge from './badge';
import server from '../libs/server';
import './top-nav.css';

export default class TopNav extends Component {
  render() {
    let classNames = ['top-nav'];
    let cartCount = 0;

    return <div className={classNames.join(' ')}>
      <div className='container'>
        <div className='top-nav-brand sm-hidden'>
          <Link to='/'>SONSURE.CO.KR</Link>
        </div>

        <div className='top-nav-links'>
          {!server.user && <Link to='/login' className='top-nav-link'>로그인</Link>}
          {!server.user && <Link to='/register' className='top-nav-link'>회원가입</Link>}

          {!!server.user &&
            <span className='top-nav-link'>
              <img className='top-nav-avatar' src={`${server.apiBase}/users/${server.user.id}/avatar`} width={20} height={20} alt={server.user.nickname} /> <span>{server.user.nickname} 님</span>

              {/*<ul className='top-nav-sub-menu'>
                <li><Link to='/my-page'>마이페이지</Link></li>
                <li><Link to='/my-page/orders'>주문내역</Link></li>
                <li><Link to='/logout'>로그아웃</Link></li>
              </ul>*/}
            </span>
          }

          {!!server.user && <Link to='/logout' className='top-nav-link'>로그아웃</Link>}

          {/*<Link to='/cart' className='top-nav-link'>
            <span>장바구니</span>
            {cartCount > 0 && <Badge>{cartCount}</Badge>}
          </Link>*/}
        </div>
      </div>
    </div>;
  }
}
