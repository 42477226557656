import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import FooterLogo from '../images/footer-logo.png';

export default class Footer extends Component {
  render() {
    let classNames = ['footer'];

    return <div className={classNames.join(' ')}>
      <ul className='footer-menu'>
        <li className='footer-menu-item'>
          <Link to='/about'>손수레 소개</Link>
        </li>
        <li className='footer-menu-item'>
          <Link to='/access-terms'>이용약관</Link>
        </li>
        <li className='footer-menu-item'>
          <Link to='/privacy-terms'>개인정보취급방침</Link>
        </li>
        <li className='footer-menu-item'>
          <Link to='/about-map'>오시는 길</Link>
        </li>
      </ul>

      <div className='container'>
        <img className='footer-logo' src={FooterLogo} alt='sonsure.co.kr' width={80} height={64} />

        <dl>
          <dt>회사명</dt>
          <dd>(주)손수레</dd>
          <dt>대표</dt>
          <dd>백종운</dd>
          <br/>
          <dt>대표전화</dt>
          <dd>070-8899-3311</dd>
          <dt>주소</dt>
          <dd>대전 유성구 덕진동 46-2 손수레 농장</dd>
          <br/>
          <dt>사업자등록번호</dt>
          <dd>334-88-00217</dd>
          <dt>통신판매업신고</dt>
          <dd>제 2020-대전유성-0150호 <a href='http://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=3348800217'>사업자정보확인</a></dd>
        </dl>

        <div className='footer-copyright'>
          Copyright &copy; (주)손수레 All Rights Reserved.
        </div>
      </div>
    </div>;
  }
}
