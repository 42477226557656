import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Page from '../components/page';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import { Form } from '../components/form';
import server from '../libs/server';
import './login.css';
import { getQueryParam } from '../libs/util';
import naverLoginImage from '../images/naver-login.png';

class Login extends Component {
  state = {
    username: '',
    loggedIn: false
  };

  get redirectUrl() {
    return getQueryParam('ret') || '/';
  }

  async componentDidMount() {
    let username = localStorage.getItem('login.username');

    this.setState({
      loggedIn: await server.authenticate(),
      username
    });
  }

  onSubmit = async (values) => {
    await server.logIn(values);

    if (values.rememberMe) {
      localStorage.setItem('login.username', values.username);
    }
  }

  render() {
    if (this.state.loggedIn) {
      return <Redirect to='/' />;
    }

    return <Page>
      <Breadcrumb>
        <BreadcrumbItem linkTo='/login'>로그인</BreadcrumbItem>
      </Breadcrumb>

      <div className='login-panel'>
        <div className='login-form'>
          <div className='login-header'>로그인</div>

          <Form onSubmit={this.onSubmit} redirectTo={this.redirectUrl} noButtons>
            <input name='username' defaultValue={this.state.username} required placeholder='아이디' autoFocus />
            <input type='password' name='password' required placeholder='비밀번호' />
            <label><input type='checkbox' name='rememberMe' /> <span>아이디 저장</span></label>
            <button type='submit' className='large primary'>로그인</button>
          </Form>

          <div className='login-social-services'>
            <a href='https://api.wisewize.me/sonsure/social-login/naver'>
              <img src={naverLoginImage} alt='네이버 아이디로 로그인' width={185} height={40} />
            </a>
          </div>

          <div className='login-buttons'>
            <Link to='/register'><button>회원가입</button></Link>  
            <Link to='/find-username'><button>아이디 찾기</button></Link>  
            <Link to='/reset-password'><button>비밀번호 찾기</button></Link>  
          </div>
        </div>
      </div>
    </Page>;
  }
}

export default Login;
