import React, { Component } from 'react';
import Page from '../components/page';
import JumboSlider from '../components/jumbo-slider';
import ContentStyle from '../components/content-style';
import server from '../libs/server';
import BbsWidget from './bbs-widget';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderItems: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    let res = await server.get(`/page-groups/main-slider/pages`);

    this.setState({
      sliderItems: res.data.map(entry => ({
        url: entry.files.length > 0 ? entry.files[0].url : '',
        title: entry.title,
        content: entry.content
      }))
    });
  }

  render() {
    return <Page>
      <ContentStyle pageGroupName='main-slider' />
      <JumboSlider items={this.state.sliderItems} height='400px' />

      <div className='row gutter'>
        <div className='col half sm-full'>
          <BbsWidget boardName='notice' />
          <BbsWidget boardName='free' />
        </div>
        <div className='col half sm-full'>
          <BbsWidget boardName='journey' />
          <BbsWidget boardName='press' />
        </div>
      </div>
    </Page>;
  }
}

export default Home;
