import React, { Component } from 'react';
import Authentication from '../components/authentication';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import { Form, FormField } from '../components/form';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import { FileContainer, FileUploader } from '../components/file-uploader';
import TinyEditor from '../components/tiny-editor';
import Loader from '../components/loader';
import server from '../libs/server';
import './bbs-write.css';
import boardInfo from '../bbs-board-info.json';

class BbsReply extends Component {
  get postId() {
    return this.props.match.params.postId;
  }

  get boardName() {
    return this.props.match.params.boardName;
  }

  isAllowed(featureName) {
    return boardInfo[this.boardName].allows.indexOf(featureName) >= 0;
  }

  loadPost = async (postId = this.postId) => {
    let res = await server.get(`/bulletin-posts/${postId}`);
    let fileContainer = null;

    if (this.isAllowed('upload') && res.data.board.storageId) {
      fileContainer = new FileContainer(res.data.board.storageId);
    }

    return {
      post: res.data,
      board: res.data.board,
      fileContainer
    };
  }

  onSubmit = async (values, post, fileContainer) => {
    let res = await server.post(`/bulletin-posts/${post.id}/reply`, {
      captcha: values.captcha,
      title: values.title,
      content: values.content,
      secret: values.secret,
      categoryId: post.category ? post.category.id : null
    });

    await fileContainer.applyCollection(`${res.data}/files`);
  }

  renderForm({ post, board, fileContainer }) {
    return <div className='bbs-write-form side-gutter'>
      <Form
        redirectTo={`/community/${this.boardName}`}
        onSubmit={values => this.onSubmit(values, post, fileContainer)}
      >
        <input name='captcha' />

        <FormField label='제목'>
          <input
            type='text'
            name='title'
            className='full-width'
            placeholder='제목을 입력하세요.'
            required
            autoFocus
            defaultValue={'RE: ' + post.title}
          />
        </FormField>

        <FormField label='내용'>
          <TinyEditor
            id='tiny-editor-1'
            name='content'
            allowTextStyle={this.isAllowed('html')}
            allowImage={!!fileContainer}
            contentCss={`${server.apiBase}/content-styles/${board.contentStyleId}/style.css`}
            fileContainer={fileContainer}
          />
        </FormField>

        {!!fileContainer &&
          <FormField label='파일'>
            <FileUploader fileContainer={fileContainer} />
          </FormField>
        }

        <FormField>
          <label><input type='checkbox' name='secret' defaultChecked={post.secret} /> 비밀글</label>
        </FormField>
      </Form>
    </div>;
  }

  render() {
    return <Page>
      <Authentication />
      <Loader onLoad={this.loadPost}>
        {data => <div>
          <PageHeader>{data.board.title}</PageHeader>
          <Breadcrumb>
            <BreadcrumbItem linkTo={`/community/${this.boardName}`}>{data.board.title}</BreadcrumbItem>
          </Breadcrumb>

          {this.renderForm(data)}
        </div>}
      </Loader>
    </Page>;
  }
}

export default BbsReply;
