import React, { Component } from 'react';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import Loader from '../components/loader';
import server from '../libs/server';
import Loading from '../images/loading.png';
import './activity.css';

class Activity extends Component {
  state = {
    page: 1,
    entries: [],
    endOfData: false,
    loading: false
  };

  loadData = async (page = this.state.page) => {
    const limit = 10;

    this.setState({ loading: true });

    let res = await server.get(`/sonsure-activities?offset=${(page - 1) * limit}&limit=${limit}&sort=-id`);

    this.setState({
      loading: false,
      entries: this.mergeEntries(res.data),
      page,
      endOfData: res.data.length < limit
    });
  }

  loadMore = () => {
    this.loadData(this.state.page + 1);
  }

  mergeEntries(entries) {
    let newEntries = entries.filter(entry => !this.state.entries.some(p => p.id === entry.id));
    return this.state.entries.concat(newEntries);
  }

  renderActivities = entries => {
    return entries.map(entry => {
      // 해시태그에 링크를 걸어줌.
      // 페이스북의 경우 #2017 처럼 숫자로만 이루어진 해시태그는 취급하지 않기 때문에 정규식에 적용함.
      let processedMessage = entry.content.replace(
        /#(?!\d+(?:\s|$))([^\s]+)/g,
        '<a class="activity-tag" href="https://www.facebook.com/hashtag/$1" target="_blank">$&</a>'
      );

      return <div key={entry.id} id={`activity-item-${entry.id}`} className='activity-item row'>
        <div className='activity-date col quarter sm-full'>
          {entry.postedAt.substring(0, 10)}
        </div>
        <div className='activity-content col'>
          {entry.image && <div className='activity-picture'>
            <a href={entry.link} target='_blank' rel='external noopener noreferrer'><img src={entry.image.url} alt={entry.id} /></a>
          </div>}
          <div className='activity-message' dangerouslySetInnerHTML={{ __html: processedMessage }} />
        </div>
      </div>;
    });
  }

  render() {
    return <Page>
      <PageHeader>손수레 활동</PageHeader>
      <Breadcrumb>
        <BreadcrumbItem linkTo='/activity'>손수레 활동</BreadcrumbItem>
      </Breadcrumb>

      <Loader onLoad={this.loadData}>
        {() => <div className='activity-list'>
          {this.renderActivities(this.state.entries)}
        </div>}
      </Loader>

      {!this.state.endOfData &&
        <div className='activity-more'>
          {this.state.loading &&
            <button className='large' disabled><img src={Loading} width={31} height={31} alt='loading' /></button>
          }

          {!this.state.loading &&
            <button className='large' onClick={this.loadMore}>더 보기</button>
          }
        </div>
      }
    </Page>;
  }
}

export default Activity;
