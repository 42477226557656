import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from '../components/page';

class PageNotFound extends Component {
  render() {
    return <Page>
      <div className='login-panel'>
        <div className='page-help'>
          <p>페이지가 존재하지 않습니다.</p>

          <p>    
            <Link to='/'><button className='large primary'>홈으로</button></Link>
          </p>
        </div>
      </div>
    </Page>;
  }
}

export default PageNotFound;
