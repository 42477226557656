import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './tab-menu.css';

export class TabItem extends Component {
  render() {
    let { active, linkTo } = this.props;
    let classNames = ['tab-item'];

    if (active) {
      classNames.push('active');
    }

    return <li className={classNames.join(' ')}>
      {linkTo && 
        <Link to={linkTo}><button>{this.props.children}</button></Link>
      }
      {!linkTo && 
        <button>{this.props.children}</button>
      }
    </li>;
  }
}

export class TabMenu extends Component {
  render() {
    return <ul className='tab-menu'>
      {this.props.children}
    </ul>;
  }
}
