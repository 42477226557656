import React, { Component } from 'react';
import Page from '../components/page';
import JumboSlider from '../components/jumbo-slider';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';
import Pagination from '../components/pagination';
import Loader from '../components/loader';
import server from '../libs/server';
import './shop.css';
import { formatCurrency } from '../libs/util';

class ShopItemSearchForm extends Component {
  onChange(v) {
    if (this.props.onChange) {
      this.props.onChange({
        sort: v
      });
    }
  }

  render() {
    let sort = this.props.sort;

    return <div className='shop-item-search-form'>
      <select value={sort} onChange={e => this.onChange(e.target.value)}>
        <option value='-id'>신상품 순으로 정렬</option>
        <option value='-cartCount'>인기 순으로 정렬</option>
        <option value='-price'>높은 가격 순으로 정렬</option>
        <option value='price'>낮은 가격 순으로 정렬</option>
      </select>
    </div>;
  }
}

class ShopItem extends Component {
  render() {
    let item = this.props.item;

    return <div className='shop-item'>
      <div className='shop-item-image'>
        <img src={item.thumbUrl} alt={item.id} />
      </div>
      <div className='shop-item-title'>
        {item.title}
      </div>
      <div className='shop-item-description'>
        {item.description}
      </div>
      <div className='shop-item-price'>
        {formatCurrency(item.price)}원
      </div>
    </div>;
  }
}

class Shop extends Component {
  loadSlider = async () => {
    let res = await server.get(`/page-groups/shop-slider/pages`);
    let sliderItems = res.data.map(entry => ({
      url: entry.files.length > 0 ? entry.files[0].url : '',
      content: entry.content
    }));

    return sliderItems;
  }

  loadItems = async (page = 1, sort = '-id') => {
    const limit = 12;
    let res = await server.get(`/shop-items?offset=${(page - 1) * limit}&limit=${limit}&sort=${sort}`);
    return {
      data: res.data,
      pagination: res.pagination,
      sort
    };
  }

  render() {
    return <Page>
      <Loader onLoad={this.loadSlider}>
        {sliderItems => <JumboSlider items={sliderItems} />}
      </Loader>

      <Breadcrumb>
        <BreadcrumbItem linkTo='/shop'>장터</BreadcrumbItem>
      </Breadcrumb>

      <Loader onLoad={this.loadItems}>
        {(res, load) => <div className='shop-item-collection'>
          <ShopItemSearchForm sort={res.sort} onChange={({ sort }) => load(1, sort)} />

          {res.pagination.count > 5 &&
            <div className='shop-item-list'>
              <div className='row gutter'>
                {res.data.map(item => <div key={item.id} className='col quarter md-one-third sm-half'>
                  <ShopItem item={item} />
                </div>)}
              </div>
            </div>
          }

          {res.pagination.count <= 5 &&
            <div className='shop-item-not-ready'>
              죄송합니다. 상품을 준비하고 있습니다.
            </div>
          }

          <Pagination {...res.pagination} onChange={page => load(page, res.sort)} />
        </div>}
      </Loader>
    </Page>;
  }
}

export default Shop;
