import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loader from './loader';
import './form.css';

export class FormText extends Component {
  render() {
    return <div className='form-text'>{this.props.children}</div>;
  }
}

export class FormField extends Component {
  render() {
    let classNames = ['form-field'];

    if (this.props.important) {
      classNames.push('important');
    }

    return <div className={classNames.join(' ')}>
      <div className='form-label'>
        {this.props.important && <span className='form-label-important'>*</span>}
        {this.props.label}
      </div>
      <div className='form-control'>
        {this.props.children}
      </div>
    </div>;
  }
}

function gatherAllNamedElements(elements) {
  let result = [];

  for (let elem of elements) {
    if (elem.name) {
      result.push(elem);
    }

    if (elem.children && elem.children.length > 0) {
      result = result.concat(gatherAllNamedElements(elem.children));
    }
  }

  return result;
}

export class Form extends Component {
  state = {
    submitting: false,
    submitted: false
  };

  mounted = false;

  static getFormValues(form) {
    let values = {};
    let elements = gatherAllNamedElements(form.children);

    for (let elem of elements) {
      if (elem.type === 'checkbox') {
        values[elem.name] = elem.checked;
      } else {
        values[elem.name] = elem.value === '' ? null : elem.value;
      }
    }

    return values;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  processSubmit = async values => {
    try {
      await this.props.onSubmit(values);

      if (!this.mounted) {
        return;
      }

      if (this.props.redirectTo) {
        this.setState({ submitted: true });
      } else {
        this.setState({
          submitting: false
        });
      }
    } catch (e) {
      alert(e.message);
      this.setState({ submitting: false });
    }
  }

  onSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ submitted: false, submitting: true });

    if (this.props.onSubmit) {
      let values = Form.getFormValues(e.target);
      this.processSubmit(values);
    }
  }

  render() {
    let classNames = ['form'];

    if (this.props.horizontal) {
      classNames.push('horizontal');
    }

    if (this.props.redirectTo && this.state.submitted) {
      return <Redirect to={this.props.redirectTo} />;
    }

    return <form
      className={classNames.join(' ')}
      onSubmit={this.onSubmit}
    >
      {this.props.children}

      {!this.props.noButtons &&
        <div className='form-field'>
          <div className='form-label'> </div>
          <div className='form-control'>
            <div className='form-buttons'>
              <button type='submit' className='primary'>{this.props.submitButton || '확인'}</button>

              {this.props.cancelButton &&
                <Link to={this.props.redirectTo}><button>{typeof this.props.cancelButton === 'boolean' ? '뒤로가기' : this.props.cancelButton}</button></Link>
              }
            </div>
          </div>
        </div>
      }

      <Loader active={this.state.submitting} />
    </form>;
  }
}
