import React, { Component } from 'react';
import Page from '../components/page';
import PageHeader from '../components/page-header';
import { Breadcrumb, BreadcrumbItem } from '../components/breadcrumb';

const googleMapApiKey = 'AIzaSyDaaU4cA7UPQ2MayAMobO-9TN_B0iihaVo';
const placeId = 'ChIJhaR4feM1ZTURN9qbakt5F-w';

class AboutMap extends Component {
  render() {
    return <Page>
      <PageHeader>오시는 길</PageHeader>
      <Breadcrumb>
        <BreadcrumbItem linkTo={`/about-map`}>오시는 길</BreadcrumbItem>
      </Breadcrumb>

      <div className='row gutter'>
        <div className='col full'>
          <iframe
            title='오시는 길'
            width='100%'
            height={400}
            frameBorder={0}
            style={{ border: 0 }}
            src={`https://www.google.com/maps/embed/v1/place?key=${googleMapApiKey}&q=place_id:${placeId}`}
            allowFullScreen
          />
        </div>

        <div className='col full'>
          <dl className='data-table'>
            <dt>회사명</dt>
            <dd>(주)손수레</dd>
            <dt>주소</dt>
            <dd>대전 유성구 덕진동 46-2 손수레 농장</dd>
            <dt>대표전화</dt>
            <dd>070-8899-3311</dd>
          </dl>
        </div>
      </div>
    </Page>;
  }
}

export default AboutMap;
