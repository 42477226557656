import React, { Component } from 'react';
import './jumbo-slider.css';

class JumboSlider extends Component {
  static defaultProps = {
    items: [],
    height: '500px',
    duration: 5000
  };

  state = {
    prevIndex: 0,
    currentIndex: 0,
    autoSlide: true
  };

  slideTimer = null;

  componentDidMount() {
    this.startSlide();
  }

  componentWillUnmount() {
    this.stopSlide();
  }

  startSlide() {
    if (this.slideTimer) {
      clearInterval(this.slideTimer);
    }

    this.slideTimer = setInterval(() => {
      this.changeSlide(this.state.currentIndex + 1, true);
    }, this.props.duration);
  }

  stopSlide() {
    if (this.slideTimer) {
      clearInterval(this.slideTimer);
      this.slideTimer = null;
    }
  }

  changeSlide(idx, autoSlide = false) {
    this.setState({
      prevIndex: this.state.currentIndex,
      currentIndex: idx % this.props.items.length,
      autoSlide
    });
  }

  onDotClick(idx) {
    this.stopSlide();
    this.changeSlide(idx);
    this.startSlide();
  }

  renderSlide(idx) {
    let item = this.props.items[idx];
    let classNames = ['jumbo-slider-item'];
    let { prevIndex, currentIndex, autoSlide } = this.state;

    if (prevIndex !== currentIndex) {
      if (idx === prevIndex) {
        if (autoSlide || prevIndex < currentIndex) {
          classNames.push('slide-left-out');
        } else {
          classNames.push('slide-right-out');
        }
      } else if (idx === currentIndex) {
        if (autoSlide || prevIndex < currentIndex) {
          classNames.push('slide-left-in');
        } else {
          classNames.push('slide-right-in');
        }
      }
    }

    return <div
      key={idx}
      className={classNames.join(' ')}
      style={{
        backgroundImage: `url(${item.url})`,
        height: this.props.height
      }}
    >
      <div className='jumbo-slider-content'>
        <div dangerouslySetInnerHTML={{ __html: item.content}}></div>
      </div>
    </div>;
  }

  render() {
    if (this.props.items.length === 0) {
      return <div></div>;
    }

    return <div className='jumbo-slider' style={{ height: this.props.height }}>
      <div className='jumbo-slider-slides'>
        {this.state.prevIndex !== this.state.currentIndex && this.renderSlide(this.state.prevIndex)}
        {this.renderSlide(this.state.currentIndex)}
      </div>

      <div className='jumbo-slider-dots'>
        {this.props.items.map((item, idx) =>
          <button
            key={idx}
            className={'jumbo-slider-dot' + (idx === this.state.currentIndex ? ' active' : '')}
            onClick={() => this.onDotClick(idx)}
          />
        )}
      </div>
    </div>;
  }
}

export default JumboSlider;
