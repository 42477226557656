import React, { Component } from 'react';
import tinymce from 'tinymce';

import 'tinymce/themes/modern';
import 'tinymce/themes/mobile';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/contextmenu';

const skinUrl = '/externals/tinymce/skins/sonsure';
const languageUrl = '/externals/tinymce/langs/ko_KR.js';

class TinyEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editor: null,
      value: props.defaultValue || props.value
    };
    this.fileInput = null;
    this.fileBrowserCallback = null;
  }

  fileBrowser = async (fieldName, url, type, win) => {
    this.fileBrowserCallback = (path) => {
      win.document.getElementById(fieldName).value = path;
    };

    this.fileInput.click();
  }

  onUploadFiles = async e => {
    let files = e.target.files;
    let result = await this.props.fileContainer.uploadFiles(files);

    if (result.length > 0 && this.fileBrowserCallback) {
      this.fileBrowserCallback(result[0].url);
    }
  }

  componentDidMount() {
    let options = {
      selector: `#${this.props.id}`,
      skin_url: skinUrl,
      theme: 'modern',
      height: 300,
      statusbar: false,
      branding: false,
      menubar: false,
      language_url: languageUrl,
      content_css: this.props.contentCss,
      setup: editor => {
        this.setState({ editor });

        editor.on('keyup change', () => {
          const content = editor.getContent();

          this.setState({ value: content });

          if (this.props.onChange) {
            this.props.onChange(content);
          }
        });
      }
    };
    let plugins = '';
    let toolbar = '';

    if (this.props.allowTextStyle) {
      plugins = 'textcolor link lists fullscreen contextmenu';
      toolbar = 'formatselect forecolor | bold underline | alignleft aligncenter alignright | outdent indent | bullist numlist | link';
    }

    if (this.props.allowImage) {
      plugins += ' image';
      toolbar += ' image';
      options.file_browser_callback = this.fileBrowser;
      options.image_list = (success) => {
        if (this.props.fileContainer) {
          success(this.props.fileContainer.getCurrentFiles().map(file => ({
            title: file.name, value: file.url
          })));
        } else {
          success([]);
        }
      };
    }

    if (this.props.allowTable) {
      plugins += ' table';
      toolbar += ' table';
      options.table_default_styles = { width: '100%' };
    }

    if (toolbar) {
      plugins += ' fullscreen';
      toolbar += ' | fullscreen';
    }

    tinymce.init({
      ...options,
      plugins,
      toolbar: toolbar || false
    });
  }

  componentWillUnmount() {
    tinymce.remove(this.state.editor);
  }

  render() {
    return <div>
      <textarea
        id={this.props.id}
        name={this.props.name}
        value={this.state.value}
        onChange={() => {}}
      />

      <input
        type='file'
        ref={fileInput => this.fileInput = fileInput}
        onChange={this.onUploadFiles}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </div>;
  }
}

export default TinyEditor;
